<template>
  <div
    class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
  >
    <div class="u-display-flex">
      <span class="u-font-size-6 u-font-weight-600 u-color-grey-lighter"
        >{{ count }} campaigns</span
      >
    </div>
    <a
      ref="viewUnmapperCampaigns"
      class="u-display-flex u-cursor-pointer"
      target="_blank"
      @click="emitEvent"
    >
      <span class="u-font-size-6 u-font-weight-600 u-color-blue-base"
        >Tag unmapped campaigns</span
      >
      <rb-icon
        class="u-flex-0 rb-icon--small u-color-blue-base u-spacing-ml-xs u-spacing-mt-xxs u-flex-align-self-flex-start rotate-y-180"
        icon="arrow_back"
      />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: 0
    }
  },
  computed: {
    redirectionLink() {
      const route = this.$router.resolve({
        path: '/tools_and_settings/campaign'
      });
      return route.href;
    }
  },
  mounted() {
    // adding href link this way to fix sonar issue of security
    const anchorTagRef = this.$refs.viewUnmapperCampaigns || {};
    anchorTagRef.href = this.redirectionLink;
  },
  methods: {
    emitEvent() {
      this.$emit('onClick');
    }
  }
};
</script>
