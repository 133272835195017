<template>
  <div
    class="u-display-flex u-spacing-pt-s u-spacing-pb-s u-border-bottom u-border-width-s u-border-color-grey-xxx-light slide-panel"
    :style="{ marginInline: '-0.3rem' }"
  >
    <rb-checkbox
      v-for="(option, index) in options"
      :key="option.label"
      v-model="option.selected"
      native-value="false"
      class="selectedEntity"
      @input="(option) => selectedEntity(option, index)"
    >
      <span class="u-font-size-7 u-font-weight-600 u-color-grey-base">{{
        option.label
      }}</span></rb-checkbox
    >
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: []
    }
  },
  methods: {
    selectedEntity(e, index) {
      this.$emit('toggleCheckbox', `${this.options[index].type}---${e}`);
    }
  }
};
</script>

<style lang="css">
.slide-panel .selectedEntity.checkbox .rb-icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.2rem;
}
.slide-panel .selectedEntity.checkbox .rb-icon::before {
  font-size: 1.4rem;
}
.slide-panel .selectedEntity.checkbox .rb-control-label {
  margin-top: 0.4rem;
}
</style>
