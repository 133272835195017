<template>
  <div class="u-spacing-pv-m info-section">
    <div class="u-font-size-5 u-spacing-mb-m">{{ item.campaign_name }}</div>
    <span
      v-for="(level, index) in allowedLevels"
      :key="index"
    >
      <div class="u-display-inline-block">
        <span
          class="u-font-size-7 u-color-grey-lighter u-text-transform-capitalize"
        >
          {{ level.label }} : </span
        ><span class="u-font-size-7 u-color-grey-base">{{
          (item || {})[level.type] || 'NA'
        }}</span>
      </div>
      <div
        v-if="allowedLevels.length > 1 && allowedLevels.length - 1 !== index"
        class="u-display-inline-block u-spacing-mh-s"
      >
        <rb-icon
          class="u-flex-0 rb-icon--small u-color-grey-xxx-light"
          :icon="'dot'"
        />
      </div>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({ title: 'default' })
    },
    allowedLevels: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="css" scoped>
.info-section > * + * {
  padding-top: 0.8rem;
}
</style>
