<template>
  <div
    class="u-border-radius-s u-spacing-p-m u-position-relative"
    :style="{ 'background-color': 'rgba(255, 168, 0, 0.05)' }"
  >
    <div
      class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
    >
      <div class="u-display-flex">
        <rb-icon
          class="u-flex-0 rb-icon--small u-color-orange-base u-spacing-mr-xs u-flex-align-self-flex-start"
          icon="info-circle-fill"
        />
        <span class="u-font-size-6 u-font-weight-600"
          >Budget distribution level changed</span
        >
      </div>
    </div>
    <div class="u-spacing-pv-s">
      <span
        class="u-font-size-6 u-font-weight-400 u-display-flex u-flex-direction-column"
        ><span class="u-color-grey-mid-light"
          >You can switch between these budget distribution options as often
          needed for different budget plans. However,</span
        >
        <span class="u-color-grey-mid-light"
          >Changing distribution levels resets the plan and affects campaign
          budgets starting the next day.</span
        >
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BudgetLevelChangeWarning'
};
</script>
