<template>
  <transition name="slide1">
    ><custom-right-panel
      title="List of Unmapped Campaigns"
      listener="unmappedCampaignsToggle"
      class="slide-panel"
    >
      <div
        slot="body"
        class="panelBody"
      >
        <div class="u-spacing-pt-s sticky-section">
          <span class="u-font-size-6 u-font-weight-600 u-color-grey-lighter">
            Show campaigns that are missing
          </span>
          <select-entity
            :options="checkboxOptions"
            @toggleCheckbox="toggleCheckbox"
          />
          <cta-with-text
            :count="campaigns.count"
            class="u-spacing-pv-m"
            @onClick="redirectToTaxonomy"
          />
        </div>
        <div
          class="u-flex-1 u-display-flex u-flex-direction-column u-position-relative"
        >
          <Loader
            class="fill--parent"
            :loading="isLoading"
            :color="'#3fadf7'"
            :thickness="'.2rem'"
          />
          <campaign-details-card
            v-for="(campaign, index) in campaigns.data"
            :key="index"
            :item="campaign"
            :allowed-levels="allowedLevels"
            class="details-card"
          />
        </div>
      </div>
    </custom-right-panel>
  </transition>
</template>

<script>
import customRightPanel from '@/components/basic/customRightPanel.vue';
import campaignDetailsCard from './listItem.vue';
import RbCheckboxGroup from '@/components/basic/rbCheckboxGroup.vue';
import selectEntity from '@/pages/budget-planner-v2/create-plan/steps/selectLevel/unmappedCampaigns/selectEntity.vue';
import ctaWithText from '@/pages/budget-planner-v2/create-plan/steps/selectLevel/unmappedCampaigns/ctaWithText.vue';
import { Entities } from '@/pages/toolsAndSettings/const';
import Loader from '@/components/basic/loader';
export default {
  name: 'UnmappedCampaignsSidePanel',
  components: {
    customRightPanel,
    campaignDetailsCard,
    RbCheckboxGroup,
    selectEntity,
    ctaWithText,
    Loader
  },
  props: {
    selectedLevels: {
      type: Object,
      default: () => ({})
    },
    unmappedCampaignsWhereClause: {
      type: Array,
      default: []
    }
  },
  computed: {
    checkboxOptions() {
      return (
        Object.entries(this.selectedLevels)?.map(([key, value]) => ({
          label: key,
          type: value,
          disabled: false,
          selected: true
        })) || []
      );
    },
    allowedLevels() {
      return this.checkboxOptions.filter((option) => option?.selected);
    },
    campaigns() {
      const campaigns = this.$store.getters.getTransformedEntityPreviewData(
        Entities.campaign
      );
      return {
        count: campaigns?.count || 0,
        data: campaigns?.data || []
      };
    },
    isLoading() {
      return this.$store.getters.getEntityLoadingState(Entities.campaign);
    }
  },
  methods: {
    toggleCheckbox(data) {
      this.$emit('toggleLevel', data);
    },
    redirectToTaxonomy() {
      this.$store.dispatch('setPersistedFilters', {
        entity: Entities.campaign,
        filters: this.unmappedCampaignsWhereClause
      });
    }
  }
};
</script>

<style lang="css" scoped>
.slide1-enter {
  transform: translateX(360px);
}

.slide1-enter-active {
  transition: all 0.3s ease-in;
}

.slide1-leave-active {
  transition: all 0.3s ease-in-out;
}
.slide1-leave-to {
  transform: translateX(360px);
}
.details-card + .details-card {
  border-top: 1px solid #e9eaeb;
}
.sticky-section {
  z-index: 105;
  background-color: #fff;
  position: sticky;
  top: 66px;
}
.panelBody > * {
  padding-right: 1.6rem;
  padding-left: 1.6rem;
}
.panelBody .fill--parent {
  margin: 35vh auto 0;
  transform: translateY(-50%);
}
</style>
