<template>
  <div class="u-spacing-pt-xxl budget-planner-select-level">
    <unmapped-campaign-side-panel
      :selected-levels="selectedLevelsLabelMap"
      :unmapped-campaigns-where-clause="unmappedCampaignsWhereClause"
      @toggleLevel="toggleLevel"
    />
    <budgetPlannerHeader :step="step" />
    <div class="u-display-flex">
      <div
        class="u-width-100 u-position-relative u-display-flex u-flex-direction-column u-border-radius-s u-spacing-p-m u-max-width-50"
        :style="{ 'background-color': 'rgba(215, 38, 61, 0.05)' }"
      >
        <Loader
          :loading="load"
          class="fill--parent"
          :color="'#3fadf7'"
          :thickness="'.2rem'"
        />
        <div
          class="u-font-size-5 u-spacing-pb-m u-flex-align-items-center u-display-flex u-font-weight-600"
        >
          <div>
            <rb-icon
              class="rb-icon--medium"
              icon="segment"
            />
          </div>
          <div class="u-spacing-pl-xs">Budget distribution levels</div>
        </div>
        <div class="u-display-flex">
          <div
            v-for="(
              categorizationTypeItem, categorizationTypeIndex
            ) in categorizationTypes"
            :key="categorizationTypeIndex"
            class="u-display-flex u-flex-direction-row u-spacing-pb-s u-spacing-pt-0"
          >
            <div
              class="u-spacing-mb-s"
              :class="
                categorizationTypeItem.value === 'retailer'
                  ? 'retailer-settings-radio-width'
                  : 'u-width-240px'
              "
            >
              <rb-radio
                v-model="categorizationType"
                :native-value="categorizationTypeItem.value"
              >
                {{ categorizationTypeItem.label }}
              </rb-radio>
            </div>
          </div>
        </div>
        <div
          :key="categorizationType"
          class="u-display-flex"
        >
          <div
            v-for="(level, index) in levels"
            :key="`${index}_${filterKey}`"
            class="u-display-flex u-flex-direction-row u-spacing-mr-m"
            :class="enablePointerEvent(level) ? '' : 'u-pointer-events-none'"
          >
            <rb-select
              class="u-display-flex u-min-width-120px u-flex-align-items-center"
              :send-details="true"
              :options="filterValues"
              :on-close="
                (nData, selectedLevel) =>
                  onSelectionChange(selectedLevel, level)
              "
            >
              <div
                slot="trigger"
                class="u-spacing-p-s select-border-radius u-bg-color-white-base u-display-flex u-flex-justify-content-space-between u-font-size-7 u-flex-align-items-center u-cursor-pointer"
              >
                <rb-icon
                  class="u-flex-0 rb-icon--small u-spacing-mh-xs u-color-grey-lighter"
                  icon="segment"
                />
                <div class="u-spacing-pr-xs u-color-grey-lighter">
                  {{ level.name }} :
                </div>
                <div
                  class="u-flex-1 u-text-overflow-ellipsis u-color-grey-base"
                >
                  {{
                    (selectedLevels &&
                      selectedLevels[level.key] &&
                      selectedLevels[level.key].dimensionLabel) ||
                    'NA'
                  }}
                </div>
                <rb-icon
                  icon="arrow-down"
                  class="rb-icon--x-small padding-right-lock-icon u-spacing-pl-s u-color-grey-lighter u-cursor-pointer"
                />
                <div
                  v-if="
                    selectedLevels &&
                    selectedLevels[level.key] &&
                    selectedLevels[level.key].dimensionLabel &&
                    index + 1 === enableLevelSelection.length
                  "
                  class="u-display-flex"
                >
                  <div
                    class="u-display-flex"
                    @click.stop="removeSelectedLevel(level)"
                  >
                    <rb-icon
                      icon="cross"
                      class="rb-icon--x-small padding-right-lock-icon u-spacing-pl-s u-color-grey-lighter u-cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <template
                slot="item"
                slot-scope="option"
              >
                <div class="u-display-flex u-flex-align-items-center">
                  {{ option.dimensionLabel }}
                </div>
              </template>
            </rb-select>
          </div>
        </div>
        <budget-level-change-warning
          v-if="showBudgetLevelChangeWarning"
          class="u-spacing-mt-m"
        />
        <UnmappedCampaignsSection
          v-if="categorizationType === 'internal'"
          class="u-spacing-mt-m"
        />
      </div>
      <div
        class="u-spacing-ml-m u-spacing-p-m u-border-all u-border-radius-s u-border-color-grey-xxx-light u-flex-align-self-flex-start"
      >
        <div class="u-font-size-5">
          <div
            style="font-size: 24px"
            class="u-font-weight-600 u-spacing-pb-m"
          >
            <rb-icon
              class="iqIconSize u-color-blue-base"
              icon="light-bulb"
            /><span class="u-color-blue-base">IQ </span>Tip
          </div>
        </div>
        <div
          class="u-font-size-6 u-color-grey-mid-light u-font-weight-600 u-spacing-pb-m"
        >
          Budget distribution levels
        </div>
        <div class="u-font-size-7 u-display-flex u-flex-direction-column">
          <span class="u-color-grey-mid-light"
            >You can switch between these budget
          </span>
          <span class="u-color-grey-mid-light"
            >distribution options as often needed</span
          >
          <span class="u-color-grey-mid-light"
            >for different budget plans. However,</span
          >
          <span class="u-color-grey-mid-light"
            >changing distribution levels resets</span
          >
          <span class="u-color-grey-mid-light"
            >the plan and affects campaign</span
          >
          <span class="u-color-grey-mid-light"
            >budgets starting the next day.</span
          >
        </div>
      </div>
    </div>
    <budgetPlannerFooter
      :show-left-btn="false"
      :disable-right-btn="disablePreviewButton"
      btn-right-text="Next"
      class="u-spacing-pt-xxl"
      custom-class-right="fs-budget-plan-move-to-input-budget-plan-step"
      @onClickBtnRight="nextStep"
    />
  </div>
</template>

<script>
import budgetPlannerHeader from '@/components/ams/budgetPlanner/steps/common/header.vue';
import budgetPlannerFooter from '@/components/ams/budgetPlanner/steps/common/footer.vue';
import UnmappedCampaignsSection from '@/pages/budget-planner-v2/create-plan/steps/selectLevel/unmappedCampaigns/index.vue';
import Loader from '@/components/basic/loader';
import HttpService from '@/utils/services/http-service';
import {
  filterConfig,
  retailerCategorizationConfig,
  categorization,
  lockRetailerCategorisationAtLevel1
} from '@/pages/budget-planner-v2/create-plan/steps/selectLevel/levelConfig.js';
import { isEmpty, cloneDeep, isEqual } from 'lodash';
import UnmappedCampaignSidePanel from '@/pages/budget-planner-v2/create-plan/steps/selectLevel/unmappedCampaigns/sidePanel.vue';
import campaign from '@/pages/toolsAndSettings/campaign-taxonomy.js';
import { Entities } from '@/pages/toolsAndSettings/const';
import { eventBus } from '@/utils/services/eventBus';
import BudgetLevelChangeWarning from '@/pages/budget-planner-v2/create-plan/steps/selectLevel/budgetLevelChangeWarning.vue';
export default {
  components: {
    Loader,
    budgetPlannerHeader,
    budgetPlannerFooter,
    UnmappedCampaignsSection,
    UnmappedCampaignSidePanel,
    BudgetLevelChangeWarning
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      filterKey: 0,
      load: false,
      categorizationType: 'internal',
      selectedLevels: {},
      lockRetailerCategorisationTypeAtLevel1: [],
      categorizationTypes: [
        {
          label: 'Retailer Categorization',
          value: 'retailer'
        },
        {
          label: 'Internal Categorization',
          value: 'internal'
        }
      ],
      internalCategorizationFilters: [],
      retailerCategorizationFilters: [],
      enableLevelSelection: [],
      levels: [
        {
          name: 'Level 1',
          key: 'level1',
          previousLevel: null
        },
        {
          name: 'Level 2',
          key: 'level2',
          previousLevel: 'level1'
        }
      ],
      unmappedCampaignsWhereClause: []
    };
  },
  computed: {
    showBudgetLevelChangeWarning() {
      return !isEqual(this.selectedLevels, this.getSelectedLevels);
    },
    strategyLevels() {
      return this.$store.getters[
        'budgetPlannerOmni/getSelectedLevelsFromStrategy'
      ];
    },
    getCategorizationType() {
      return this.$store.getters['budgetPlannerOmni/getCategorizationType'];
    },
    retailer() {
      return this.$store.getters.getRetailer;
    },
    disablePreviewButton() {
      return isEmpty(this.selectedLevels);
    },
    getSelectedLevels() {
      return this.$store.getters['budgetPlannerOmni/getSelectedLevels'];
    },
    filterValues() {
      let filteredValues = [];
      const selectedDimensionNames = [];
      for (let levelKey in this.selectedLevels) {
        if (this.selectedLevels[levelKey])
          selectedDimensionNames.push(
            this.selectedLevels[levelKey].dimensionName
          );
      }
      if (this.categorizationType === 'retailer') {
        filteredValues = this.retailerCategorizationFilters;
      } else {
        filteredValues = this.internalCategorizationFilters;
      }
      return filteredValues.filter(
        (item) => !selectedDimensionNames.includes(item.dimensionName)
      );
    },
    selectedLevelsLabelMap() {
      return Object.values(this.selectedLevels || {}).reduce((map, level) => {
        if (level?.dimensionLabel && level?.dimensionName)
          map[level.dimensionLabel] = level.dimensionName;
        return map;
      }, {});
    }
  },
  watch: {
    categorizationType(newVal, oldVal) {
      this.$store.commit(
        'budgetPlannerOmni/MUTATE_CATEGORIZATION_TYPE',
        newVal
      );
      this.selectedLevels = {};
      this.enableLevelSelection = [];
      if (oldVal !== newVal && newVal !== 'internal') {
        eventBus.$emit('unmappedCampaignsToggle', 'close');
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.categorizationType = this.getCategorizationType;
      const config = filterConfig[this.retailer];
      this.lockRetailerCategorisationTypeAtLevel1 =
        lockRetailerCategorisationAtLevel1[this.retailer] || [];
      const retailerKeyIdMap = retailerCategorizationConfig[
        this.retailer
      ].reduce((acc, item) => {
        acc[item.name] = item.id;
        return acc;
      }, {});
      const { cubeName, pageName } = config?.body || {};
      for (const key in this.selectedLevels) {
        this.enableLevelSelection.push(key);
      }
      this.fetchLevels(config, cubeName, pageName, retailerKeyIdMap);
    },

    async fetchLevels(config, cubeName, pageName, retailerKeyIdMap) {
      this.load = true;
      let failure = false;
      try {
        const response = await HttpService.post(config.endPoint, {
          cubeName,
          pageName
        });
        const filterMetadata = response?.data?.response?.filterMetadata || [];
        for (let item of filterMetadata) {
          if (item.dimensionType === categorization.internal) {
            this.internalCategorizationFilters = item?.dropDownOptions || [];
          } else if (item.dimensionType === categorization.retailer) {
            this.retailerCategorizationFilters = item?.dropDownOptions?.filter(
              (option) => {
                if (
                  Object.keys(retailerKeyIdMap).includes(option.dimensionName)
                ) {
                  option.dimensionId = retailerKeyIdMap[option.dimensionName];
                  return option;
                }
              }
            );
          }
        }
      } catch {
        failure = true;
      } finally {
        this.$snackbar.open({
          message: failure
            ? config?.message?.failure
            : config?.message?.success,
          duration: failure ? 10000 : 3000,
          buttonColor: '#f5d908',
          actionText: ''
        });
        this.selectedLevels = this.getSelectedLevels;
        for (let levelKey in this.selectedLevels) {
          this.enableLevelSelection.push(levelKey);
        }
        this.fetchUnmappedCampaigns(this.selectedLevels);
        this.filterKey++;
        this.load = false;
      }
    },
    enablePointerEvent(level) {
      return !(level.key === 'level2' && !this.selectedLevels.level1);
    },
    removeSelectedLevel(level) {
      if (level.key) {
        delete this.selectedLevels[level.key];
      }
      this.selectedLevels = cloneDeep(this.selectedLevels);
      this.fetchUnmappedCampaigns(this.selectedLevels);
      this.enableLevelSelection = [this.enableLevelSelection[0]];
    },
    nextStep() {
      this.restoreSeasonalityIfExists();
      this.updateSelectedLevelState();
      this.$emit('nextStep', {});
    },
    updateSelectedLevelState() {
      this.$store.dispatch(
        'budgetPlannerOmni/updateSelectedLevels',
        this.selectedLevels
      );
    },
    onSelectionChange(selectedLevel, level) {
      if (selectedLevel?.length) {
        if (level.key === 'level2' && this.categorizationType === 'retailer') {
          const dimensionName = selectedLevel?.[0]?.dimensionName;
          if (
            this.lockRetailerCategorisationTypeAtLevel1.includes(dimensionName)
          ) {
            this.$snackbar.open({
              message:
                'The selected retailer categorization is not supported at level 2',
              duration: 3000,
              buttonColor: '#f5d908',
              actionText: ''
            });
            return;
          }
        }
        this.selectedLevels[level.key] = selectedLevel[0];
        this.selectedLevels = { ...this.selectedLevels };
        this.fetchUnmappedCampaigns(this.selectedLevels);
        if (!this.enableLevelSelection.includes(level.key)) {
          this.enableLevelSelection.push(level.key);
        }
        this.filterKey++;
      }
    },
    fetchUnmappedCampaigns(levels = {}) {
      const destructuredLevels = Object.values(levels || {});
      if (
        this.categorizationType === 'internal' &&
        destructuredLevels?.length &&
        campaign?.config?.readOnlyColumns?.[this.retailer]
      ) {
        const whereClause = destructuredLevels
          .map((level) =>
            level?.dimensionName
              ? {
                  dimensionName: level.dimensionName,
                  dimensionValue: null,
                  operator: 'IS_NULL',
                  esDataSetName: campaign.config.esDataSetName
                }
              : null
          )
          .filter(Boolean);
        const entityData = {
          entity: Entities.campaign,
          retailer: this.retailer,
          metrics: campaign.config.readOnlyColumns[this.retailer].map(
            ({ key }) => key
          ),
          entityColumn: campaign.config.primaryKey,
          whereClause: {
            dimensionNameValueList: whereClause
          }
        };
        this.unmappedCampaignsWhereClause = whereClause;
        this.$store.dispatch('fetchUnmappedCampaigns', entityData);
      }
    },
    toggleLevel(data) {
      const [dimension, value] = data.split('---');
      const selectedLevels = Object.entries(this.selectedLevels).reduce(
        (map, [levelKey, level]) => {
          if (level.dimensionName === dimension) {
            if (value === 'true') {
              map[levelKey] = level;
            }
          } else {
            map[levelKey] = level;
          }
          return map;
        },
        {}
      );
      this.fetchUnmappedCampaigns(selectedLevels);
    },
    restoreSeasonalityIfExists() {
      if (isEqual(this.selectedLevels, this.strategyLevels)) {
        this.$store.commit('budgetPlannerOmni/RESTORE_SEASONALITY_EVENT_MAP');
      }
    }
  }
};
</script>

<style lang="css" scoped>
.budget-planner-select-level .select-border-radius {
  border-radius: 100px;
  border: 1px solid #e9eaeb;
}
.budget-planner-select-level .icon-lock:before {
  font-size: 10px !important;
}
.budget-planner-select-level .padding-right-lock-icon {
  padding-right: 14px;
}
.retailer-settings-radio-width {
  width: 200px;
}
</style>
