var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-spacing-pt-xxl budget-planner-select-level"},[_c('unmapped-campaign-side-panel',{attrs:{"selected-levels":_vm.selectedLevelsLabelMap,"unmapped-campaigns-where-clause":_vm.unmappedCampaignsWhereClause},on:{"toggleLevel":_vm.toggleLevel}}),_c('budgetPlannerHeader',{attrs:{"step":_vm.step}}),_c('div',{staticClass:"u-display-flex"},[_c('div',{staticClass:"u-width-100 u-position-relative u-display-flex u-flex-direction-column u-border-radius-s u-spacing-p-m u-max-width-50",style:({ 'background-color': 'rgba(215, 38, 61, 0.05)' })},[_c('Loader',{staticClass:"fill--parent",attrs:{"loading":_vm.load,"color":'#3fadf7',"thickness":'.2rem'}}),_c('div',{staticClass:"u-font-size-5 u-spacing-pb-m u-flex-align-items-center u-display-flex u-font-weight-600"},[_c('div',[_c('rb-icon',{staticClass:"rb-icon--medium",attrs:{"icon":"segment"}})],1),_c('div',{staticClass:"u-spacing-pl-xs"},[_vm._v("Budget distribution levels")])]),_c('div',{staticClass:"u-display-flex"},_vm._l((_vm.categorizationTypes),function(categorizationTypeItem,categorizationTypeIndex){return _c('div',{key:categorizationTypeIndex,staticClass:"u-display-flex u-flex-direction-row u-spacing-pb-s u-spacing-pt-0"},[_c('div',{staticClass:"u-spacing-mb-s",class:categorizationTypeItem.value === 'retailer'
                ? 'retailer-settings-radio-width'
                : 'u-width-240px'},[_c('rb-radio',{attrs:{"native-value":categorizationTypeItem.value},model:{value:(_vm.categorizationType),callback:function ($$v) {_vm.categorizationType=$$v},expression:"categorizationType"}},[_vm._v(" "+_vm._s(categorizationTypeItem.label)+" ")])],1)])}),0),_c('div',{key:_vm.categorizationType,staticClass:"u-display-flex"},_vm._l((_vm.levels),function(level,index){return _c('div',{key:(index + "_" + _vm.filterKey),staticClass:"u-display-flex u-flex-direction-row u-spacing-mr-m",class:_vm.enablePointerEvent(level) ? '' : 'u-pointer-events-none'},[_c('rb-select',{staticClass:"u-display-flex u-min-width-120px u-flex-align-items-center",attrs:{"send-details":true,"options":_vm.filterValues,"on-close":function (nData, selectedLevel) { return _vm.onSelectionChange(selectedLevel, level); }},scopedSlots:_vm._u([{key:"item",fn:function(option){return [_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[_vm._v(" "+_vm._s(option.dimensionLabel)+" ")])]}}],null,true)},[_c('div',{staticClass:"u-spacing-p-s select-border-radius u-bg-color-white-base u-display-flex u-flex-justify-content-space-between u-font-size-7 u-flex-align-items-center u-cursor-pointer",attrs:{"slot":"trigger"},slot:"trigger"},[_c('rb-icon',{staticClass:"u-flex-0 rb-icon--small u-spacing-mh-xs u-color-grey-lighter",attrs:{"icon":"segment"}}),_c('div',{staticClass:"u-spacing-pr-xs u-color-grey-lighter"},[_vm._v(" "+_vm._s(level.name)+" : ")]),_c('div',{staticClass:"u-flex-1 u-text-overflow-ellipsis u-color-grey-base"},[_vm._v(" "+_vm._s((_vm.selectedLevels && _vm.selectedLevels[level.key] && _vm.selectedLevels[level.key].dimensionLabel) || 'NA')+" ")]),_c('rb-icon',{staticClass:"rb-icon--x-small padding-right-lock-icon u-spacing-pl-s u-color-grey-lighter u-cursor-pointer",attrs:{"icon":"arrow-down"}}),(
                  _vm.selectedLevels &&
                  _vm.selectedLevels[level.key] &&
                  _vm.selectedLevels[level.key].dimensionLabel &&
                  index + 1 === _vm.enableLevelSelection.length
                )?_c('div',{staticClass:"u-display-flex"},[_c('div',{staticClass:"u-display-flex",on:{"click":function($event){$event.stopPropagation();return _vm.removeSelectedLevel(level)}}},[_c('rb-icon',{staticClass:"rb-icon--x-small padding-right-lock-icon u-spacing-pl-s u-color-grey-lighter u-cursor-pointer",attrs:{"icon":"cross"}})],1)]):_vm._e()],1)])],1)}),0),(_vm.showBudgetLevelChangeWarning)?_c('budget-level-change-warning',{staticClass:"u-spacing-mt-m"}):_vm._e(),(_vm.categorizationType === 'internal')?_c('UnmappedCampaignsSection',{staticClass:"u-spacing-mt-m"}):_vm._e()],1),_c('div',{staticClass:"u-spacing-ml-m u-spacing-p-m u-border-all u-border-radius-s u-border-color-grey-xxx-light u-flex-align-self-flex-start"},[_c('div',{staticClass:"u-font-size-5"},[_c('div',{staticClass:"u-font-weight-600 u-spacing-pb-m",staticStyle:{"font-size":"24px"}},[_c('rb-icon',{staticClass:"iqIconSize u-color-blue-base",attrs:{"icon":"light-bulb"}}),_c('span',{staticClass:"u-color-blue-base"},[_vm._v("IQ ")]),_vm._v("Tip ")],1)]),_c('div',{staticClass:"u-font-size-6 u-color-grey-mid-light u-font-weight-600 u-spacing-pb-m"},[_vm._v(" Budget distribution levels ")]),_vm._m(0)])]),_c('budgetPlannerFooter',{staticClass:"u-spacing-pt-xxl",attrs:{"show-left-btn":false,"disable-right-btn":_vm.disablePreviewButton,"btn-right-text":"Next","custom-class-right":"fs-budget-plan-move-to-input-budget-plan-step"},on:{"onClickBtnRight":_vm.nextStep}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-font-size-7 u-display-flex u-flex-direction-column"},[_c('span',{staticClass:"u-color-grey-mid-light"},[_vm._v("You can switch between these budget ")]),_c('span',{staticClass:"u-color-grey-mid-light"},[_vm._v("distribution options as often needed")]),_c('span',{staticClass:"u-color-grey-mid-light"},[_vm._v("for different budget plans. However,")]),_c('span',{staticClass:"u-color-grey-mid-light"},[_vm._v("changing distribution levels resets")]),_c('span',{staticClass:"u-color-grey-mid-light"},[_vm._v("the plan and affects campaign")]),_c('span',{staticClass:"u-color-grey-mid-light"},[_vm._v("budgets starting the next day.")])])}]

export { render, staticRenderFns }