export const filterConfig = {
  walmart: {
    body: {
      cubeName: 'walmart_campaigns_campaigns_workbench',
      pageName: 'walmart_campaigns'
    },
    endPoint: 'FILTER_METADATA_V2',
    message: {
      success: 'Latest taxonomy fetched successfully',
      failure: 'Oops something went wrong, pelase try again'
    }
  },
  amazon: {
    body: {
      cubeName: 'ams_campaigns_campaign_workbench',
      pageName: 'campaigns'
    },
    endPoint: 'FILTER_METADATA_V2',
    message: {
      success: 'Latest taxonomy fetched successfully',
      failure: 'Oops something went wrong, pelase try again'
    }
  }
};

export const categorization = {
  internal: 'CAMPAIGN_INTERNAL_CATALOGUE',
  retailer: 'AMAZON_CATEGORY'
};

export const retailerCategorizationConfig = {
  walmart: [
    {
      name: 'advertiser_name',
      id: 'advertiser_id'
    }
  ],
  amazon: [
    {
      name: 'profile_name',
      id: 'profile_id'
    },
    {
      name: 'portfolio_name',
      id: 'portfolio_id'
    }
  ]
};

export const lockRetailerCategorisationAtLevel1 = {
  amazon: ['profile_name']
};
