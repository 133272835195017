<template>
  <div
    class="u-border-radius-s u-spacing-p-m u-position-relative unmapped-campaign-section"
    :style="{ 'background-color': 'rgba(215, 38, 61, 0.05)' }"
  >
    <Loader
      :loading="isLoading"
      class="fill--parent"
      :color="'#3fadf7'"
      :thickness="'.2rem'"
    />
    <div
      class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
    >
      <div class="u-display-flex">
        <rb-icon
          class="u-flex-0 rb-icon--small u-color-red-base u-spacing-mr-xs u-flex-align-self-flex-start"
          icon="warning-triangle"
        />
        <span class="u-font-size-6 u-font-weight-600"
          >Budget distribution level changed</span
        >
      </div>
      <div
        v-if="count"
        class="u-display-flex u-cursor-pointer"
        @click="openSidePanel"
      >
        <span class="u-font-size-6 u-font-weight-600 u-color-blue-base"
          >View campaigns</span
        >
        <rb-icon
          class="u-flex-0 rb-icon--small u-color-blue-base u-spacing-ml-xs u-spacing-mt-xxs u-flex-align-self-flex-start rotate-y-180"
          icon="arrow_back"
        />
      </div>
    </div>
    <div class="u-spacing-pv-s">
      <span
        class="u-font-size-6 u-font-weight-400 u-display-flex u-flex-direction-column"
        ><span class="u-color-grey-mid-light"
          >There are
          <strong class="u-color-red-base">{{ count }} campaigns</strong>
          without any value for the selected campaign taxonomy columns.</span
        >
        <span class="u-color-grey-mid-light"
          >Please note that setting a budget plan without fixing this can result
          in an <strong class="u-color-red-base">Overspend</strong>.</span
        >
      </span>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/utils/services/eventBus';
import { Entities } from '@/pages/toolsAndSettings/const';
import Loader from '@/components/basic/loader';
export default {
  name: 'UnmappedCampaignsSection',
  components: { Loader },
  computed: {
    isLoading() {
      return this.$store.getters.getEntityLoadingState(Entities.campaign);
    },
    count() {
      return (
        this.$store.getters.getTransformedEntityPreviewData(Entities.campaign)
          ?.count || 0
      );
    }
  },
  methods: {
    openSidePanel() {
      eventBus.$emit('unmappedCampaignsToggle', 'open');
    }
  }
};
</script>
<style lang="css">
.unmapped-campaign-section .fill--parent {
  background: #e9eaeb;
}
</style>
